body {
    background-color: var(--background-tan);
}

.overview {
    margin: 60px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.order-meta {
    justify-self: flex-start;
}

.logo-brown {
    max-width: 150px;
    height: auto;
    align-self: center;
}

.order-totals-row {
    border-top: 2px solid var(--accent-brown);
}

.order-data {
    align-self: center;
    justify-content: space-evenly;
    border-collapse: collapse;
    margin: 1rem;
}
#order-notes {
    max-width: 400px;
}

th {
    padding: 10px;
}
td {
    text-align: center;
    padding: 5px 10px;
}

.cost {
    margin: 20px;
    align-self: flex-end;
}

button {
    align-self: center;
    margin-top: 10px;
}

strong {
    color: var(--accent-brown);
}

#order-number {
    color: var(--accent-brown);
}

#fiuo {
    position: absolute;
    align-self: flex-end;
}
#fiuo label {
    font-size: 16px;
    margin-right: 4px;
}

@media print {
    .overview {
        font-size: 12px;
    }

    #fiuo {
        position: relative;
        align-self: flex-end;
        font-size: 12px;
        margin-right: 0;
    }

    #fiuo label {
        font-size: 12px;
        margin-right: 0;
    }

    .delete-btn, .print-btn {
        visibility: hidden;
    }
}